import { transactionService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { CurrencyEnum, PaymentModeEnum, TransactionTypeEnum } from '@/resource/enum';
import { TransactionList, SaveTransaction } from '@/resource/model';
import { NormalSelectOptions } from '@/resource/model/common';
import { messageError, translation } from '@/utils';
import { ElForm } from 'element-ui/types/form';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class AddTransactionDetails extends mixins(DialogMixin) {
  @Prop({
    type: Object,
    required: false,
    default: () => {
      return null;
    }
  })
  public transaction!: TransactionList | null;

  @Prop({
    type: Array,
    required: true
  })
  public customers!: NormalSelectOptions;

  public form: Partial<SaveTransaction> = {
    abstracts: '',
    amount: undefined,
    bankName: '',
    bankNumber: '',
    createTime: '',
    currencyId: undefined,
    currencyName: '',
    customerId: undefined,
    name: '',
    remark: '',
    serialNumber: '',
    settlementMethod: undefined,
    type: undefined
  };

  public formRules: { [P in keyof Partial<SaveTransaction>]: Array<object> } = {
    createTime: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('transaction.selectTradingHours')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    settlementMethod: [
      {
        required: true,
        validator: (rule: any, value: PaymentModeEnum, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('transaction.selectPaymentMode')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    currencyId: [
      {
        required: true,
        validator: (rule: any, value: CurrencyEnum, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('transaction.selectPaymentMode')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    type: [
      {
        required: true,
        validator: (rule: any, value: TransactionTypeEnum, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('transaction.selectTransactionType')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    amount: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('transaction.inputAmount')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    customerId: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('billing.selectCustomer')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public get transactionTypes(): NormalSelectOptions {
    return transactionService.getTransactionTypes();
  }

  public get paymentModes(): NormalSelectOptions {
    return transactionService.getPaymentModes();
  }

  public get currencyTypes(): NormalSelectOptions {
    return transactionService.getCurrencyTypes();
  }

  public created(): void {
    if (!this.transaction) {
      this.title = 'transaction.add';
      return;
    }
    this.title = 'transaction.edit';
    Object.assign(this.form, this.transaction);
  }

  public onSubmit(): void {
    (this.$refs.transactionForm as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        this.form.customerName = this.customers.find(x => x.value === this.form.customerId)!.label;
        if (!this.transaction) {
          await transactionService.post(this.form as SaveTransaction);
          this.$emit('add-success');
          this.closeDialog();
          return;
        }
        await transactionService.put(this.form as SaveTransaction);
        this.$emit('edit-success', this.form);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }

  public destroyed(): void {
    this.$emit('dialog-closed');
  }
}
