import { Component, Watch } from 'vue-property-decorator';
import OsTable, { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { ImportRes, TransactionList, TransactionListQuery } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import {
  dateFormat,
  downloadFileByBlob,
  handleImportError,
  messageError,
  showWarningConfirm,
  translation
} from '@/utils';
import { Message } from 'element-ui';
import { cloneDeep } from 'lodash-es';
import { ImportFile } from '@/views/dialogs';
import { ApiResponse } from '@/api/axios';
import { customerService, transactionService } from '@/api';
import AddTransactionDetails from './add-transaction-details/add-transaction-details.vue';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import { PaymentModeEnum, TransactionStatusEnum, TransactionTypeEnum } from '@/resource/enum';
import { NormalSelectOptions } from '@/resource/model/common';
import { CustomColumnMixin } from '@/mixins/custom-column';
@Component({
  components: { AddTransactionDetails, ImportFile }
})
export default class TransactionDetails extends mixins(PagingMixin, CustomColumnMixin) {
  public tableOption: OsTableOption<TransactionList> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<TransactionList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'createTime',
      label: 'transaction.tradingHours',
      showOverflowTooltip: true,
      fixed: true,
      minWidth: '180px',
      formatter: (rowData: object): string => {
        return dateFormat((rowData as TransactionList).createTime);
      }
    },
    {
      prop: 'abstracts',
      label: 'transaction.abstract',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'serialNumber',
      label: 'transaction.serialNumber',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'type',
      label: 'transaction.transactionType',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        if (!(rowData as TransactionList).type) {
          return '--';
        }
        return translation(`transactionType.${TransactionTypeEnum[(rowData as TransactionList).type]}`);
      }
    },
    {
      prop: 'settlementMethod',
      label: 'billing.paymentMode',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        if (!(rowData as TransactionList).settlementMethod) {
          return '--';
        }
        return translation(`paymentMode.${PaymentModeEnum[(rowData as TransactionList).settlementMethod]}`);
      }
    },
    {
      prop: 'currencyName',
      label: 'transaction.transactionType',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'amount',
      label: 'transaction.amount',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        return (rowData as TransactionList).amount.toFixed(2);
      }
    },
    {
      prop: 'customerName',
      label: 'billing.customer',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'bankNumber',
      label: 'transaction.counterpartyAccount',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'name',
      label: 'transaction.counterpartyName',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'bankName',
      label: 'transaction.counterpartyBank',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        if (!(rowData as TransactionList).status) {
          return translation('common.unKnownStatus');
        }
        return translation(`transactionStatus.${TransactionStatusEnum[(rowData as TransactionList).status]}`);
      }
    },
    {
      prop: 'billCode',
      label: 'transaction.writeOffBillingCode',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'customerId',
      label: 'billing.customer',
      option: {
        placeholder: 'billing.selectCustomer',
        filterable: true
      },
      optionData: []
    },
    {
      type: 'Select',
      field: 'status',
      label: 'common.status',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: transactionService.getStatus
    },
    {
      type: 'Select',
      field: 'type',
      label: 'transaction.transactionType',
      option: {
        placeholder: 'transaction.selectTransactionType'
      },
      optionData: transactionService.getTransactionTypes
    },
    {
      type: 'Select',
      field: 'settlementMethod',
      label: 'transaction.paymentMode',
      option: {
        placeholder: 'transaction.selectPaymentMode'
      },
      optionData: transactionService.getPaymentModes
    },
    {
      type: 'DateRangePicker',
      field: 'createTime',
      label: 'transaction.tradingHours',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        }
      }
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'finance:write:off:save',
      handleClick: (): void => {
        this.openTransactionDialog();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.import',
      operationType: 'import',
      icon: 'el-icon-upload2',
      permissionCode: 'finance:write:off:importData',
      handleClick: (): void => {
        this.openImportFileDialog();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      permissionCode: 'finance:write:off:batchDelete',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchDeleteTransaction();
      }
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<TransactionList> = {
    fixed: 'right',
    width: '120px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'finance:write:off:edit',
        handleClick: (item: TransactionList): void => {
          this.openTransactionDialog(item);
        }
      }
    ]
  };
  /**
   * 控制导入的dialog显示隐藏
   */
  public importDialogVisible = false;

  public importTitle = 'transaction.import';

  public editRow: TransactionList | null = null;

  public dialogVisible = false;

  public customers: NormalSelectOptions = [];

  private selectedRows: Array<TransactionList> = [];

  private queryForm: Partial<TransactionListQuery> & { createTime: Array<string> } = {
    customerId: undefined,
    keywords: '',
    settlementMethod: undefined,
    status: undefined,
    type: undefined,
    createTime: []
  };

  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'transaction-details');
    this.getCustomers();
    this.loadData();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public dialogClosed(): void {
    this.editRow = null;
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.clearSelection();
    this.loadData();
  }

  public handleSelectionChange(selectedData: Array<TransactionList>): void {
    this.selectedRows = selectedData;
  }

  public editSuccess(data: TransactionList): void {
    const findItem = this.tableOption.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
  }

  public downloadTemplate(): void {
    (this.$refs.importDialog as ImportFile).setDownloadLoading(true);
    transactionService
      .downloadTemplate()
      .then((blob: any) => {
        downloadFileByBlob(`${translation('transaction.importTemplate')}.xlsx`, blob);
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        (this.$refs.importDialog as ImportFile).setDownloadLoading(false);
      });
  }

  public importSuccess(path: string): void {
    transactionService
      .import(path)
      .then(() => {
        this.reloadData();
        Message.success(translation('dialog.importSuccess'));
        this.importDialogVisible = false;
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importDialog as ImportFile).setLoading(false);
      });
  }

  private openTransactionDialog(data: TransactionList | null = null): void {
    if (data) {
      this.editRow = cloneDeep(data);
    }
    this.dialogVisible = true;
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<TransactionList>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType !== 'add' && x.operationType !== 'import') {
        x.disabled = value.length === 0;
      }
    });
  }

  @Watch('queryForm.createTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.createStartTime = undefined;
      this.queryForm.createEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.createStartTime = dateFormat(value[0]);
      this.queryForm.createEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  private async batchDeleteTransaction(): Promise<void> {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          const idList: Array<number> = this.selectedRows.map(x => x.id);
          await transactionService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private loadData(): void {
    this.tableOption.loading = true;
    transactionService
      .getList(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private openImportFileDialog(): void {
    this.importDialogVisible = true;
  }

  private getCustomers(): void {
    customerService
      .getAllUsingCustomer()
      .then(res => {
        const customerQuery = this.queryItemsOption.find(x => x.field === 'customerId');
        this.customers = customerQuery!.optionData = res.map(x => {
          return { label: x.companyName, value: x.id };
        });
      })
      .catch(error => {
        messageError(error);
      });
  }

  private clearSelection(): void {
    (this.$refs.transactionTable as OsTable).clearSelection();
    this.selectedRows = [];
  }
}
